body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inp-box {
  margin-top: 10px;
  margin-bottom: 10px;
}

.video-modal {
  width: 90% !important;
}

.video-modal video {
  width: 100%;
  margin-top: 5px;
}

.video-modal .ant-modal-close {
  right: 5px;
  top: 1px;
  color: #000;
  width: 38px;
  height: 38px;
}

.video-modal .ant-modal-content {
  padding: 0px 5px;
}

.ant-image .ant-image-mask {
  display: none;
}

.ant-carousel .slick-slide img {
  cursor: pointer;
}

.product-table .ant-pagination-next {
  margin-right: 50px;
}